import React, { FC } from 'react';
import { connectStateResults } from 'react-instantsearch-dom';

import { Container } from 'layout';

import { CustomResultsHandlerProps } from './models';

import './CustomResultsHandler.scss';

const Results = ({ searchResults, children, noResultsText }) => (
  <>
    {searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <Container>
        <div role="alert" className="filters__no-results standfirst" aria-live="assertive">
          {noResultsText}
        </div>
      </Container>
    )}
  </>
);

const CustomResults = connectStateResults(Results);

const CustomResultsHandler: FC<CustomResultsHandlerProps> = ({ noResultsText, children }) => (
  <CustomResults noResultsText={noResultsText}>{children}</CustomResults>
);

export default CustomResultsHandler;
